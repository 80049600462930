import Header from '@/components/screens/Home/header/Header'
import { SearchToursService } from '@/services/search-tours/SearchToursService.service'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
//@ts-ignore
import ImageGallery from 'react-image-gallery'
import style from './Hotel.module.scss'
import infoSvg1 from '@/assets/images/info-card/rest.svg'
import infoSvg2 from '@/assets/images/info-card/about.svg'
import infoSvg3 from '@/assets/images/info-card/meal.svg'
import infoSvg4 from '@/assets/images/info-card/about.svg'
import infoSvg5 from '@/assets/images/info-card/geo.svg'
import defaultImg from '@/assets/images/default-home.svg'
import kidSvg from '@/assets/images/trip/kids.svg'
import './Hotel.scss'
import HotelInfoCard from '@/components/ui/hotel-info-card/HotelInfoCard'
import { FindNameToKey } from '@/utils/find-name-to-key/FindNameToKey'
import { ApiData } from '@/api/apiData/api.data'
import { PropsDateService } from '@/services/blog/blog.service.interface'
import { DateServiceHotel } from '@/services/date/hotel.service'
import TableList from './table-list/TableList'
import BoxForm from './search-box/BoxForm'
import { useDateFlatPick } from './inputs-hidden-box/custom-function/useDateFlatPick'
import LeftNav from './navigation/LeftNav'
import RightNav from './navigation/RightNav'
import LoadingPageHotel from '@/components/LoadingPage/LoadingPageHotel'
import Footer from '../footer/Footer'
import { useTranslation } from 'react-i18next'
import { LangContext } from '@/components/provider/MainProvider'

import picCountIcon from '../../../assets/images/pictureCounter.svg'
import MapGoogle from './MapGoogle'
import Reviews from './Reviews'
import DetailedRatings from './DetailedRatings'
import getGoogleReviews from '@/components/Request/GoogleReviews'
import getGoogleRating from '@/components/Request/GoogleRating'
import ReviewsLinkRating from './ReviewsLinkRating'
import { Helmet } from 'react-helmet'

interface GoogleReviews {
	author_name: string
	author_url: string
	language: string
	original_language: string
	profile_photo_url: string
	rating: number
	relative_time_description: string
	text: string
	time: number
	translated: boolean
}

interface ModalDesctiption {
	icon: string
	title: string
	txt: string
}
function createMarkup(text: string) {
	return { __html: text }
}
const ModalDesctiption: FC<any> = ({
	currentDescriptionModal,
	openModal,
	setOpenModal
}) => {
	return (
		<div
			className={
				style.wrapper_modal +
				' ' +
				(openModal && style.wrapper_modal_active)
			}
		>
			<div className={style.bodyModal}>
				<button
					className={style.bodyModal_close}
					onClick={() => setOpenModal(false)}
				></button>
				<div className={style.reviews_row_title}>
					<div className={style.reviews_wrapper_icon_name}>
						<img src={currentDescriptionModal?.icon} alt='' />
						<div className={style.reviews_author_published_date}>
							<div className={style.reviews_author}>
								{currentDescriptionModal?.title}
							</div>
						</div>
					</div>
				</div>
				<div>
					<p
						className={style.reviews_txt}
						dangerouslySetInnerHTML={createMarkup(
							currentDescriptionModal?.txt
						)}
					></p>
				</div>
			</div>
		</div>
	)
}

const Hotel: FC<any> = ({ timeData, setTimeData, checkout, setCheckout }) => {
	const [fixPrice, setFixPrice] = React.useState('')
	const [picCount, setPicCount] = React.useState(1)
	const { lang, toggleLang: setLang } = useContext(LangContext)
	const { id } = useParams()
	const { t, i18n } = useTranslation()
	const currentLanguage = i18n.language
	const [hotelEnabled, setHotelEnabled] = useState<string | undefined>(id)
	const [newTimeDate, setNewTimeData] = useState(timeData)
	const [loading, setLoading] = React.useState(true)

	const [actualDate, setActualDate] = React.useState([])
	const [images, setImages] = useState(null)
	const [imagesLength, setImagesLength] = React.useState('...')
	const [date, setDate] = React.useState<null | string>(
		newTimeDate?.data || null
	)
	const [dateCheckRange, setDateCheckRange] = useState<boolean>(
		timeData?.date_check_range ? timeData?.date_check_range : false
	)
	const [countryCode, setCountryCode] = React.useState('')
	const [townFrom, setTownFrom] = React.useState('')
	const [currentReview, setCurrentReview] = useState('all')
	const [currentRating, setCurrentRating] = useState<string>('0')
	const [currentReviewLenght, setCurrentReviewLenght] = useState(0)
	const [currentRatingAverage, setCurrentRatingAverage] =
		useState<string>('0')
	let stateHotelCard = useLocation()

	// =====================================

	const [firstDateAvailable, setFirstDateAvailable] =
		React.useState<string>('')
	const queryClient = useQueryClient()

	// =====================================
	const getHotel = useQuery(
		'get-hotel',
		() =>
			//@ts-ignore
			SearchToursService.getHotel({ id }),
		{
			enabled: !!id,
			onSuccess: data => {
				setCountryCode(data.data?.countryCode)
				setTownFrom(data.data?.townFrom)
				setLoading(false)
			}
		}
	)

	const toursInfo: any = timeData
	const testRequest: any = {
		// fromTownCode: toursInfo?.townFrom || null,
		countryCode: toursInfo?.countryCode || null,
		adults: toursInfo?.adult || 2,
		childs: toursInfo?.child || 0,
		nights_min: toursInfo?.nights_min || 1,
		nights_max: toursInfo?.nights_max || 17,
		date: toursInfo?.data || '',
		price_range_min: toursInfo?.price_range_min || 100,
		price_range_max: toursInfo?.price_range_max || 10000,
		childYear: toursInfo?.childs_age || [],
		meal_types: toursInfo?.meal_types || [
			'RO',
			'BB',
			'HB',
			'FB',
			'AI',
			'UAI'
		]
	}

	const offerList = useQuery(
		'get-offer-left',
		() =>
			SearchToursService.getOfferList(
				{ ...newTimeDate, hotelCode: id },
				date,
				firstDateAvailable
			),
		{
			enabled: !!hotelEnabled,
			onSuccess: () => setHotelEnabled(undefined),
			select: data => data.data.slice(0, 5)
		}
	)
	const offerListRefetch = async () => {
		await queryClient.fetchQuery('get-offer-left')
	}

	useEffect(() => {
		if (offerList.isFetched && offerList?.data.length == 0) {
			setHotelEnabled('true')
			offerListRefetch()
		}
	}, [firstDateAvailable])

	const [firstPriceOfferList, setFirstPriceOfferList] = React.useState('')
	useEffect(() => {
		if (offerList.isFetched) {
			setFirstPriceOfferList(offerList.data?.[0]?.price)
		}
	}, [offerList.isFetched])

	useEffect(() => {
		if (stateHotelCard?.state?.fixPrice) {
			setFixPrice(stateHotelCard?.state?.fixPrice)
		}
	}, [stateHotelCard?.state?.fixPrice])

	const [dataReq, setDataReq] = React.useState(testRequest)

	const modalRef = useRef(null)
	let navigate = useNavigate()
	const calendarRef = useRef<HTMLParagraphElement | null>(null)
	const [openCalendar, setOpenCalendar] = React.useState(false)
	const [directionName, setDirectionName] = React.useState(
		FindNameToKey(ApiData.directionsData2, dataReq?.countryCode)
	)
	const [fromTown, setFromTown] = React.useState<null | string>(
		FindNameToKey(ApiData.directionsData, dataReq?.fromTownCode)
	)

	const getDate = useMutation(
		'get-date-tours',
		(data: PropsDateService) => DateServiceHotel.getDate(data, id),
		{
			onError: () => {
				setActualDate([])
			},
			onSuccess: data => {
				let dataArray = Array.isArray(data.data)
					? data.data
					: Object.values(data.data)

				if (dataArray.length === 0) {
					setActualDate([])
					setDate(null)
				} else {
					setActualDate(Object.values(data.data))
					setFirstDateAvailable(data.data[0])
				}

				let dateString = date?.replace(/\s/g, '')
				let dateArray = dateString?.split(',')
				const ElementsInArray = dataArray.some((element: string) =>
					dateArray?.includes(element)
				)
				if (!ElementsInArray) {
					setDate(null)
				}
			}
		}
	)

	useEffect(() => {
		if (getHotel.isSuccess && getHotel.data.data.photoList) {
			let images = getHotel.data.data.photoList.map((el: any) => ({
				original: `https://api.mandarina.lv/${el.urlPhoto}`,
				thumbnail: `https://api.mandarina.lv/${el.urlPhoto}`
			}))
			if (images.length === 0) {
				images = [...Array(1)].map(() => ({
					original: defaultImg,
					thumbnail: defaultImg
				}))
			}
			setImages(images)
			setImagesLength(images.length)
		}
	}, [getHotel.data])

	React.useEffect(() => {
		let handler = (e: any) => {
			//@ts-ignore
			if (!modalRef.current?.contains(e.target)) {
				setOpenForm(0)
			}
		}
		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	}, [])

	const [openForm, setOpenForm] = useState(0)

	useDateFlatPick({
		getDate,
		townFrom: newTimeDate.townFrom == '' ? townFrom : newTimeDate.townFrom,
		setActualDate,
		countryCode:
			newTimeDate.countryCode == ''
				? countryCode
				: newTimeDate.countryCode,
		meal_types: newTimeDate.meal_types,
		calendarRef,
		openCalendar,
		newTimeDate
	})

	const handleSlide = (slideIndex: number) => {
		const currentSlideNumber = slideIndex + 1
		setPicCount(currentSlideNumber)
	}

	const sendOrder = ({
		checkIn,
		checkOut,
		hotelName,
		meal,
		price,
		room
	}: any) => {
		const newOrder = {
			checkIn,
			checkOut,
			hotelName,
			meal,
			price,
			room,
			rating: getHotel.data?.data.rating,
			adult: newTimeDate.adult,
			child: newTimeDate.child,
			childs_age: newTimeDate.childs_age,
			photo:
				getHotel.data?.data?.photoList.length !== 0
					? `https://api.mandarina.lv/${getHotel.data?.data?.photoList[0].urlPhoto}`
					: defaultImg,
			location_lv: getHotel.data?.data.location_lv,
			location_ru: getHotel.data?.data.location_ru,
			location_en: getHotel.data?.data.location_en,
			hotelCode: getHotel.data?.data.hotelCode
		}
		setCheckout(newOrder)
		setTimeData(newTimeDate)
		//@ts-ignore
		localStorage.setItem('checkout', JSON.stringify(newOrder))
		navigate('/checkout')
	}

	const ref = useRef<any>(null)
	const [isVisibleCard, setIsVisibleCard] = useState(0)

	let detailed_ratings = getHotel.data?.data?.detailed_ratings
		? JSON.parse(getHotel.data?.data?.detailed_ratings)
		: null

	const [googleRating, setGoogleRating] = useState('')

	const [googleReviewsUrl, setGoogleReviewsUrl] = useState('')
	const [hotelReviewsGoogle, setHotelReviewsGoogle] = useState<
		Array<GoogleReviews>
	>([])

	useEffect(() => {
		getHotel.data?.data &&
			getGoogleReviews(
				getHotel.data?.data,
				setHotelReviewsGoogle,
				setGoogleReviewsUrl
			)
		getHotel.data?.data &&
			getGoogleRating(getHotel.data?.data, setGoogleRating)
	}, [getHotel.data?.data.longitude])

	useEffect(() => {
		if (
			currentReview == 'all' &&
			getHotel.data?.data?.ta_rating &&
			googleRating != ''
		) {
			let newCurrentRating = (
				Number(getHotel.data?.data?.ta_rating + googleRating) / 2
			).toFixed(2)
			setCurrentRating(newCurrentRating)
			setCurrentReviewLenght(
				getHotel.data?.data?.hotelReviews?.length +
					hotelReviewsGoogle?.length
			)
		} else if (currentReview == 'google') {
			setCurrentRating(googleRating)
			setCurrentReviewLenght(hotelReviewsGoogle?.length)
		} else if (currentReview == 'tripadvisor') {
			setCurrentRating(getHotel.data?.data?.ta_rating)
			setCurrentReviewLenght(getHotel.data?.data?.hotelReviews?.length)
		} else if (getHotel.data?.data?.ta_rating) {
			setCurrentRating(getHotel.data?.data?.ta_rating)
			setCurrentReviewLenght(getHotel.data?.data?.hotelReviews?.length)
		} else if (googleRating != '') {
			setCurrentRating(googleRating)
			setCurrentReviewLenght(hotelReviewsGoogle?.length)
		}
	}, [
		googleRating,
		getHotel.data?.data?.ta_rating,
		currentReview,
		hotelReviewsGoogle
	])

	useEffect(() => {
		if (getHotel.data?.data?.ta_rating && googleRating != '') {
			let newCurrentRating = (
				Number(getHotel.data?.data?.ta_rating + googleRating) / 2
			).toFixed(2)
			setCurrentRatingAverage(newCurrentRating)
		} else if (getHotel.data?.data?.ta_rating) {
			setCurrentRatingAverage(getHotel.data?.data?.ta_rating)
		} else if (googleRating != '') {
			setCurrentRatingAverage(googleRating)
		}
	}, [googleRating, getHotel.data?.data?.ta_rating])

	const [swiperRef, setSwiperRef] = useState(null)

	const prevHandler = () => {
		//@ts-ignore
		swiperRef != null && swiperRef.slidePrev()
	}

	const nextHandler = () => {
		//@ts-ignore
		swiperRef.slideNext()
	}

	const maxLength = 60
	function sliceString(str: string) {
		if (str.length > maxLength) {
			return str.slice(0, maxLength) + '...'
		}
		return str
	}
	const [openModal, setOpenModal] = useState(false)
	const [currentDescriptionModal, setCurrentDescriptionModal] =
		useState<ModalDesctiption | null>()

	const [hotelForKidsList_lv, setHotelForKidsList_lv] = useState('')
	const [hotelForKidsList, setHotelForKidsList] = useState('')

	const [hotelActiveRestList_lv, setHotelActiveRestList_lv] = useState('')
	const [hotelActiveRestList, setHotelActiveRestList] = useState('')

	const [hotelAboutList_lv, setHotelAboutList_lv] = useState('')
	const [hotelAboutList, setHotelAboutList] = useState('')

	const [hotelFoodList_lv, setHotelFoodList_lv] = useState('')
	const [hotelFoodList, setHotelFoodList] = useState('')

	const [hotelNoteList_lv, setHotelNoteList_lv] = useState('')
	const [hotelNoteList, setHotelNoteList] = useState('')

	const [hotelLocationList_lv, setHotelLocationList_lv] = useState('')
	const [hotelLocationList, setHotelLocationList] = useState('')

	const targetElementRef = useRef<HTMLDivElement | null>(null)
	const scrollToElement = () => {
		if (targetElementRef.current) {
			targetElementRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			})
		}
	}
	useEffect(() => {
		let separator = ' | '
		let txt = ''
		if (getHotel?.data?.data?.hotelForKidsList.length !== 0) {
			getHotel?.data?.data.hotelForKidsList.map((el: any, key: any) => {
				txt =
					txt +
					el.forKids +
					(key == getHotel?.data?.data?.hotelForKidsList.length - 1
						? ''
						: separator)
			})
			setHotelForKidsList(txt)
		}
		txt = ''
		if (getHotel?.data?.data?.hotelForKidsList_lv.length !== 0) {
			getHotel?.data?.data.hotelForKidsList_lv.map(
				(el: any, key: any) => {
					txt =
						txt +
						el.forKids +
						(key ==
						getHotel?.data?.data?.hotelForKidsList_lv.length - 1
							? ''
							: separator)
				}
			)
			setHotelForKidsList_lv(txt)
		}

		txt = ''
		if (getHotel?.data?.data?.hotelActiveRestList_lv.length !== 0) {
			getHotel?.data?.data.hotelActiveRestList_lv.map(
				(el: any, key: any) => {
					txt =
						txt +
						el.activeRest +
						(key ==
						getHotel?.data?.data?.hotelActiveRestList_lv.length - 1
							? ''
							: separator)
				}
			)
			setHotelActiveRestList_lv(txt)
		}
		txt = ''
		if (getHotel?.data?.data?.hotelActiveRestList.length !== 0) {
			getHotel?.data?.data.hotelActiveRestList.map(
				(el: any, key: any) => {
					txt =
						txt +
						el.activeRest +
						(key ==
						getHotel?.data?.data?.hotelActiveRestList.length - 1
							? ''
							: separator)
				}
			)
			setHotelActiveRestList(txt)
		}

		txt = ''
		if (getHotel?.data?.data?.hotelAboutList_lv.length !== 0) {
			getHotel?.data?.data.hotelAboutList_lv.map((el: any, key: any) => {
				txt =
					txt +
					el.hotel +
					(key == getHotel?.data?.data?.hotelAboutList_lv.length - 1
						? ''
						: separator)
			})
			setHotelAboutList_lv(txt)
		}
		txt = ''
		if (getHotel?.data?.data?.hotelAboutList.length !== 0) {
			getHotel?.data?.data.hotelAboutList.map((el: any, key: any) => {
				txt =
					txt +
					el.hotel +
					(key == getHotel?.data?.data?.hotelAboutList.length - 1
						? ''
						: separator)
			})
			setHotelAboutList(txt)
		}

		txt = ''
		if (getHotel?.data?.data?.hotelFoodList_lv.length !== 0) {
			getHotel?.data?.data.hotelFoodList_lv.map((el: any, key: any) => {
				txt =
					txt +
					el.food +
					(key == getHotel?.data?.data?.hotelFoodList_lv.length - 1
						? ''
						: separator)
			})
			setHotelFoodList_lv(txt)
		}
		txt = ''
		if (getHotel?.data?.data?.hotelFoodList.length !== 0) {
			getHotel?.data?.data.hotelFoodList.map((el: any, key: any) => {
				txt =
					txt +
					el.food +
					(key == getHotel?.data?.data?.hotelFoodList.length - 1
						? ''
						: separator)
			})
			setHotelFoodList(txt)
		}

		txt = ''
		if (getHotel?.data?.data?.hotelNoteList_lv.length !== 0) {
			getHotel?.data?.data.hotelNoteList_lv.map((el: any, key: any) => {
				txt =
					txt +
					el.notes +
					(key == getHotel?.data?.data?.hotelNoteList_lv.length - 1
						? ''
						: separator)
			})
			setHotelNoteList_lv(txt)
		}
		txt = ''
		if (getHotel?.data?.data?.hotelNoteList.length !== 0) {
			getHotel?.data?.data.hotelNoteList.map((el: any, key: any) => {
				txt =
					txt +
					el.notes +
					(key == getHotel?.data?.data?.hotelNoteList.length - 1
						? ''
						: separator)
			})
			setHotelNoteList(txt)
		}

		txt = ''
		if (getHotel?.data?.data?.hotelLocationList_lv.length !== 0) {
			getHotel?.data?.data.hotelLocationList_lv.map(
				(el: any, key: any) => {
					txt =
						txt +
						el.location +
						(key ==
						getHotel?.data?.data?.hotelLocationList_lv.length - 1
							? ''
							: separator)
				}
			)
			setHotelLocationList_lv(txt)
		}
		txt = ''
		if (getHotel?.data?.data?.hotelLocationList.length !== 0) {
			getHotel?.data?.data.hotelLocationList.map((el: any, key: any) => {
				txt =
					txt +
					el.location +
					(key == getHotel?.data?.data?.hotelLocationList.length - 1
						? ''
						: separator)
			})
			setHotelLocationList(txt)
		}
	}, [getHotel?.data?.data])

	if (loading) return <LoadingPageHotel />

	return (
		<>
			{getHotel.isSuccess && (
				<>
					<Helmet>
						<title>
							{getHotel.data?.data?.name +
								' - ceļojumu aģentūra Mandarina '}
						</title>
						{/* <meta
							name='description'
							content={'description'}
						/> */}
					</Helmet>

					<div className='bg-gray-wrapper'>
						<Header />
					</div>
					<div className='container-xxl'>
						<ModalDesctiption
							openModal={openModal}
							setOpenModal={setOpenModal}
							currentDescriptionModal={currentDescriptionModal}
						/>
						<div className='mt-36 max-[600px]:mt-24'>
							{images && (
								<div className={style.slider}>
									<ImageGallery
										ref={ref}
										items={images}
										thumbnailPosition={
											window.innerWidth > 1000
												? 'left'
												: 'bottom'
										}
										showPlayButton={false}
										showFullscreenButton={false}
										onSlide={handleSlide}
										renderLeftNav={(
											onClick: any,
											disabled: any
										) => (
											<LeftNav
												onClick={() => {
													onClick()
												}}
												disabled={
													ref?.current?.state
														.currentIndex === 0
												}
											/>
										)}
										renderRightNav={(
											onClick: any,
											disabled: any
										) => (
											<RightNav
												onClick={() => {
													onClick()
												}}
												disabled={
													ref?.current?.state
														.currentIndex ===
													getHotel.data.data
														?.photoList?.length -
														1
												}
											/>
										)}
									/>
									<div className={style.pictureCounter}>
										<img
											src={picCountIcon}
											alt=''
											className={style.picCountIcon}
										/>
										<p>
											{picCount}/{imagesLength}
										</p>
									</div>
								</div>
							)}
						</div>
						<ReviewsLinkRating
							currentRating={currentRatingAverage}
							googleReviewsUrl={googleReviewsUrl}
							ta_url={getHotel.data?.data?.ta_url}
							hotelReviews={getHotel.data?.data?.hotelReviews}
							hotelReviewsGoogle={hotelReviewsGoogle}
							forMob={true}
							scrollToElement={scrollToElement}
						/>
						<div className={style.crumbs}>
							<span>
								<Link to='/search-tours'>
									{t('search_tours')}
								</Link>
								{' > '}
							</span>
							<span>
								<Link to='/search-tours'>
									{lang === 'ru'
										? getHotel.data.data.location_ru
										: lang === 'lv'
										? getHotel.data.data.location_lv
										: t('search_tours')}
								</Link>
							</span>
						</div>
						<div className={`${style.description} `}>
							<h2>{getHotel.data?.data.name}</h2>
							<ReviewsLinkRating
								currentRating={currentRatingAverage}
								googleReviewsUrl={googleReviewsUrl}
								ta_url={getHotel.data?.data?.ta_url}
								hotelReviews={getHotel.data?.data?.hotelReviews}
								hotelReviewsGoogle={hotelReviewsGoogle}
								forMob={false}
								scrollToElement={scrollToElement}
							/>
						</div>
						<div className={style.bg}>
							{/* <div className={style.price}>
								<h3>
									{`${t('c')}`} {}
									{fixPrice ? (
										<>
											{fixPrice.replace('.', ',')}€{' '}
											<span>{t('Uzdiviem')}</span>
										</>
									) : (
										<>
											{firstPriceOfferList?.replace(
												'.',
												','
											)}
											€<span>{t('at_all')}</span>
										</>
									)}
								</h3>
								<p>
									{t(
										'the_price_depends_on_the_date_of_departure_and_the_type_of_food'
									)}
								</p>
							</div> */}
							<div className='row '>
								<div className='col-12'>
									<BoxForm
										setOpenForm={setOpenForm}
										openForm={openForm}
										newTimeDate={newTimeDate}
										modalRef={modalRef}
										setNewTimeData={setNewTimeData}
										actualDate={actualDate}
										setHotelEnabled={setHotelEnabled}
										offerList={offerList}
										date={date}
										setDate={setDate}
										loadingDate={getDate.isLoading}
										calendarRef={calendarRef}
										openCalendar={openCalendar}
										setOpenCalendar={setOpenCalendar}
										dateCheckRange={dateCheckRange}
										setDateCheckRange={setDateCheckRange}
									/>
								</div>
							</div>
						</div>

						<TableList
							offerList={offerList.data}
							date={date}
							sendOrder={sendOrder}
							hotelEnabled={hotelEnabled}
							getHotel={getHotel.data.data}
						/>

						<div className={style.hotelInfo}>
							{getHotel.data?.data.descriptionHotel.length !==
							0 ? (
								<>
									<img
										src={
											getHotel.data.data.photoList
												.length !== 1 &&
											getHotel.data.data.photoList?.[0]
												? `https://api.mandarina.lv/${getHotel.data?.data?.photoList?.[0].urlPhoto}`
												: defaultImg
										}
										alt='description'
									/>
									<div className={style.description2}>
										<h2>{t('info_about_hotels')}</h2>

										<div>
											{currentLanguage == 'lv' &&
											getHotel.data?.data
												.descriptionHotel_lv[0]
												?.description.length >= 0 ? (
												<div
													dangerouslySetInnerHTML={{
														__html: getHotel.data
															?.data
															.descriptionHotel_lv[0]
															.description
													}}
												/>
											) : (
												<div
													dangerouslySetInnerHTML={{
														__html: getHotel.data
															?.data
															.descriptionHotel[0]
															.description
													}}
												/>
											)}
										</div>
									</div>
								</>
							) : (
								''
							)}
						</div>

						<div className={style.row_map_review + ' row'}>
							{getHotel.data?.data.latitude && (
								<div className={'col-12'}>
									<MapGoogle
										latitude={getHotel.data?.data.latitude}
										longitude={
											getHotel.data?.data.longitude
										}
									/>
								</div>
							)}
						</div>

						<div className='row'>
							{getHotel.data?.data && (
								<>
									{hotelForKidsList.length !== 0 &&
										hotelForKidsList_lv.length !== 0 && (
											<div className='col-12 col-lg-4'>
												<HotelInfoCard
													title={t(
														'services_for_children'
													)}
													img={kidSvg}
													text='rfewfe'
													isVisible={isVisibleCard}
													setIsVisible={
														setIsVisibleCard
													}
													index={1}
												>
													<div
														className={
															style.wrapper_list_InfoCard
														}
														dangerouslySetInnerHTML={createMarkup(
															currentLanguage ==
																'lv'
																? sliceString(
																		hotelForKidsList_lv
																  )
																: sliceString(
																		hotelForKidsList
																  )
														)}
													></div>
													<button
														onClick={() => {
															setCurrentDescriptionModal(
																{
																	icon: kidSvg,
																	title: t(
																		'services_for_children'
																	),
																	txt:
																		currentLanguage ==
																		'lv'
																			? hotelForKidsList_lv
																			: hotelForKidsList
																}
															)
															setOpenModal(true)
														}}
														className={
															style.btn_wrapTxt +
															(currentLanguage ==
															'lv'
																? hotelForKidsList_lv.length <
																  maxLength
																	? ' ' +
																	  style.btn_wrapTxt_hide
																	: ''
																: hotelForKidsList.length <
																  maxLength
																? ' ' +
																  style.btn_wrapTxt_hide
																: '')
														}
													>
														{t('Unwrap')}
													</button>
												</HotelInfoCard>
											</div>
										)}
									{hotelActiveRestList.length !== 0 &&
										hotelActiveRestList_lv.length !== 0 && (
											<div className='col-12 col-lg-4'>
												<HotelInfoCard
													title={t('activities')}
													img={infoSvg1}
													text='rfewfe'
													isVisible={isVisibleCard}
													setIsVisible={
														setIsVisibleCard
													}
													index={2}
												>
													<div
														className={
															style.wrapper_list_InfoCard
														}
														dangerouslySetInnerHTML={createMarkup(
															currentLanguage ==
																'lv'
																? sliceString(
																		hotelActiveRestList_lv
																  )
																: sliceString(
																		hotelActiveRestList
																  )
														)}
													></div>
													<button
														onClick={() => {
															setCurrentDescriptionModal(
																{
																	icon: infoSvg1,
																	title: t(
																		'activities'
																	),
																	txt:
																		currentLanguage ==
																		'lv'
																			? hotelActiveRestList_lv
																			: hotelActiveRestList
																}
															)
															setOpenModal(true)
														}}
														className={
															style.btn_wrapTxt +
															(currentLanguage ==
															'lv'
																? hotelActiveRestList_lv.length <
																  maxLength
																	? ' ' +
																	  style.btn_wrapTxt_hide
																	: ''
																: hotelActiveRestList.length <
																  maxLength
																? ' ' +
																  style.btn_wrapTxt_hide
																: '')
														}
													>
														{t('Unwrap')}
													</button>
												</HotelInfoCard>
											</div>
										)}
									{hotelAboutList.length !== 0 &&
										hotelAboutList_lv.length !== 0 && (
											<div className='col-12 col-lg-4'>
												<HotelInfoCard
													title={t(
														'info_about_hotels'
													)}
													img={infoSvg2}
													text='rfewfe'
													isVisible={isVisibleCard}
													setIsVisible={
														setIsVisibleCard
													}
													index={3}
												>
													<div
														className={
															style.wrapper_list_InfoCard
														}
														dangerouslySetInnerHTML={createMarkup(
															currentLanguage ==
																'lv'
																? sliceString(
																		hotelAboutList_lv
																  )
																: sliceString(
																		hotelAboutList
																  )
														)}
													></div>
													<button
														onClick={() => {
															setCurrentDescriptionModal(
																{
																	icon: infoSvg2,
																	title: t(
																		'info_about_hotels'
																	),
																	txt:
																		currentLanguage ==
																		'lv'
																			? hotelAboutList_lv
																			: hotelAboutList
																}
															)
															setOpenModal(true)
														}}
														className={
															style.btn_wrapTxt +
															(currentLanguage ==
															'lv'
																? hotelAboutList_lv.length <
																  maxLength
																	? ' ' +
																	  style.btn_wrapTxt_hide
																	: ''
																: hotelAboutList.length <
																  maxLength
																? ' ' +
																  style.btn_wrapTxt_hide
																: '')
														}
													>
														{t('Unwrap')}
													</button>
												</HotelInfoCard>
											</div>
										)}
									{hotelFoodList.length !== 0 &&
										hotelFoodList_lv.length !== 0 && (
											<div className='col-12 col-lg-4'>
												<HotelInfoCard
													title={t('meal')}
													img={infoSvg3}
													text='rfewfe'
													isVisible={isVisibleCard}
													setIsVisible={
														setIsVisibleCard
													}
													index={4}
												>
													<div
														className={
															style.wrapper_list_InfoCard
														}
														dangerouslySetInnerHTML={createMarkup(
															currentLanguage ==
																'lv'
																? sliceString(
																		hotelFoodList_lv
																  )
																: sliceString(
																		hotelFoodList
																  )
														)}
													></div>
													<button
														onClick={() => {
															setCurrentDescriptionModal(
																{
																	icon: infoSvg3,
																	title: t(
																		'meal'
																	),
																	txt:
																		currentLanguage ==
																		'lv'
																			? hotelFoodList_lv
																			: hotelFoodList
																}
															)
															setOpenModal(true)
														}}
														className={
															style.btn_wrapTxt +
															(currentLanguage ==
															'lv'
																? hotelFoodList_lv.length <
																  maxLength
																	? ' ' +
																	  style.btn_wrapTxt_hide
																	: ''
																: hotelFoodList.length <
																  maxLength
																? ' ' +
																  style.btn_wrapTxt_hide
																: '')
														}
													>
														{t('Unwrap')}
													</button>
												</HotelInfoCard>
											</div>
										)}
									{hotelNoteList.length !== 0 &&
										hotelNoteList_lv.length !== 0 && (
											<div className='col-12 col-lg-4'>
												<HotelInfoCard
													title={t('note')}
													img={infoSvg4}
													text='rfewfe'
													isVisible={isVisibleCard}
													setIsVisible={
														setIsVisibleCard
													}
													index={5}
												>
													<div
														className={
															style.wrapper_list_InfoCard
														}
														dangerouslySetInnerHTML={createMarkup(
															currentLanguage ==
																'lv'
																? sliceString(
																		hotelNoteList_lv
																  )
																: sliceString(
																		hotelNoteList
																  )
														)}
													></div>
													<button
														onClick={() => {
															setCurrentDescriptionModal(
																{
																	icon: infoSvg4,
																	title: t(
																		'note'
																	),
																	txt:
																		currentLanguage ==
																		'lv'
																			? hotelNoteList_lv
																			: hotelNoteList
																}
															)
															setOpenModal(true)
														}}
														className={
															style.btn_wrapTxt +
															(currentLanguage ==
															'lv'
																? hotelNoteList_lv.length <
																  maxLength
																	? ' ' +
																	  style.btn_wrapTxt_hide
																	: ''
																: hotelNoteList.length <
																  maxLength
																? ' ' +
																  style.btn_wrapTxt_hide
																: '')
														}
													>
														{t('Unwrap')}
													</button>
												</HotelInfoCard>
											</div>
										)}
									{hotelLocationList.length !== 0 &&
										hotelLocationList_lv.length !== 0 && (
											<div className='col-12 col-lg-4'>
												<HotelInfoCard
													title={t('location')}
													img={infoSvg5}
													text='rfewfe'
													isVisible={isVisibleCard}
													setIsVisible={
														setIsVisibleCard
													}
													index={6}
												>
													<div
														className={
															style.wrapper_list_InfoCard
														}
														dangerouslySetInnerHTML={createMarkup(
															currentLanguage ==
																'lv'
																? sliceString(
																		hotelLocationList_lv
																  )
																: sliceString(
																		hotelLocationList
																  )
														)}
													></div>
													<button
														onClick={() => {
															setCurrentDescriptionModal(
																{
																	icon: infoSvg5,
																	title: t(
																		'location'
																	),
																	txt:
																		currentLanguage ==
																		'lv'
																			? hotelLocationList_lv
																			: hotelLocationList
																}
															)
															setOpenModal(true)
														}}
														className={
															style.btn_wrapTxt +
															(currentLanguage ==
															'lv'
																? hotelLocationList_lv.length <
																  maxLength
																	? ' ' +
																	  style.btn_wrapTxt_hide
																	: ''
																: hotelLocationList.length <
																  maxLength
																? ' ' +
																  style.btn_wrapTxt_hide
																: '')
														}
													>
														{t('Unwrap')}
													</button>
												</HotelInfoCard>
											</div>
										)}
								</>
							)}
						</div>

						<div
							ref={targetElementRef}
							className={
								style.row_Reviews_DetailedRatings + ' row'
							}
						>
							{getHotel.data?.data?.hotelReviews.length > 0 ||
							hotelReviewsGoogle.length > 0 ? (
								<>
									<div
										className='col-12'
										style={{ position: 'relative' }}
									>
										<h2 className={style.reviews_h2}>
											{t('hotelReviews_reviews')}
										</h2>
										<p
											className={
												style.reviews_h2_description
											}
										>
											{t(
												'The_average_rating_reviews_Google_Tripadvisor'
											)}
										</p>
										<div
											className={
												style.wrapper_reviews_count_btn_filter
											}
										>
											<div
												className={
													style.reviews_description_star
												}
											>
												<div className='d-flex align-items-center'>
													<span
														className={
															style.reviews_description_star_value
														}
													>
														{currentRating}
													</span>
													<div
														className={
															style.reviews_description_img_star
														}
													></div>
												</div>
												{currentReviewLenght}{' '}
												{t('atsauksmes')}
											</div>

											<div
												className={
													style.wrapper_btn_filter_reviews
												}
											>
												{getHotel.data?.data
													.hotelReviews.length > 0 &&
												hotelReviewsGoogle.length >
													0 ? (
													<>
														<button
															className={
																style.all +
																' ' +
																style.btn_filter_reviews +
																' ' +
																(currentReview ==
																	'all' &&
																	style.active)
															}
															onClick={() =>
																setCurrentReview(
																	'all'
																)
															}
														>
															{t('All_reviews')}
														</button>

														<button
															className={
																style.btn_filter_reviews +
																' ' +
																(currentReview ==
																	'google' &&
																	style.active)
															}
															onClick={() =>
																setCurrentReview(
																	'google'
																)
															}
														>
															Google
														</button>
														<button
															className={
																style.btn_filter_reviews +
																' ' +
																(currentReview ==
																	'tripadvisor' &&
																	style.active)
															}
															onClick={() =>
																setCurrentReview(
																	'tripadvisor'
																)
															}
														>
															Tripadvisor
														</button>
													</>
												) : null}
											</div>
										</div>

										<div
											className={
												style.wrapper_swiper_button_r
											}
										>
											<div
												onClick={prevHandler}
												className={
													style.reviews_wrapper_swiper_button_prev_r +
													' swiper-button-prev swiper_button_prev_r'
												}
											></div>
											<div
												onClick={nextHandler}
												className={
													style.reviews_wrapper_swiper_button_next_r +
													' swiper-button-next swiper_button_next_r'
												}
											></div>
										</div>
									</div>
								</>
							) : null}

							{getHotel.data?.data.hotelReviews.length > 0 ||
							hotelReviewsGoogle.length > 0 ? (
								<div
									className={
										getHotel.data?.data?.hotelReviews
											?.length > 0
											? 'col-12 col-lg-8'
											: 'col-12'
									}
								>
									<div>
										<Reviews
											hotelReviews={
												getHotel.data?.data
													?.hotelReviews
											}
											currentReview={currentReview}
											setSwiperRef={setSwiperRef}
											hotelReviewsGoogle={
												hotelReviewsGoogle
											}
											currentLanguage={currentLanguage}
										/>
									</div>
								</div>
							) : null}
							{detailed_ratings?.length > 0 && (
								<div className='col-12 col-lg-4'>
									<DetailedRatings
										detailed_ratings={detailed_ratings}
									/>
								</div>
							)}
						</div>
					</div>
				</>
			)}
			<Footer />
		</>
	)
}

export default Hotel
